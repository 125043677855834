import React from 'react'
import Layout from '../components/layout'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { useLocale } from '../hooks/use-locale'

export default function NotFoundPage(props) {
  const locale = useLocale()

  return (
    <Layout {...props} title="page.not-found">
      <div className="c-row c-row--beta">
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
              <h2 className="section-title u-text-center">
                <FormattedMessage id="page.not-found" />
              </h2>

              <div className="c-alert-box u-text-center">
                <p>
                  <FormattedHTMLMessage
                    id="page.not-found-body"
                    values={{
                      url: `${locale}/personal-updates`,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
